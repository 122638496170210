import { Component, Vue } from "vue-property-decorator";

import { Header ,Footer} from "@/layout";
import { HomeworkModel } from "@/models";
import { TeCommon } from "../Common";

export class SearchForm {
    active = '';
    grade = '';
    cid = '';
    sid = '';
    clid = '';
    tid = '';
    student = '';
    date = '';
    name = '';
}

type response = { [key: string]: unknown };

@Component<ApTeacherHomeworkVerifyController>({
    components: {
        Header,Footer
    }
})
export default class ApTeacherHomeworkVerifyController extends Vue {
    private searchForm = new SearchForm();
    private cityList: object = {};
    private schoolList: object = {};
    private classList: object = {};
    private teacherList: object = {};
    private resultList: object = {};
    private gradeList: object = {};
    private num = 0;
    private page = 1;
    private perpage = 50;
    private maxPage = 0;
    private tid = window.localStorage.getItem('teacher_tid') as string;
    private token = window.localStorage.getItem('teacherToken') as string;

    public async created() {
        this.getResultList();
        this.cityList = await TeCommon.getTECity(this.tid);//取得該老師所屬班級的所有縣市
        this.gradeList = await TeCommon.getGrade();
    }

    //取得該老師所屬班級之該縣市分校列表
    public async getTESchool() {
        this.searchForm.sid = '';
        this.searchForm.clid = '';
        this.searchForm.tid = '';
        const cid = this.searchForm.cid;
        this.schoolList = await TeCommon.getTESchool(cid, this.tid);
        this.classList = [];
        this.teacherList = [];
    }

    // 取得該老師所屬班級之該分校班級列表
    public async getTEClass() {
        this.searchForm.clid = '';
        this.searchForm.tid = '';
        const sid = this.searchForm.sid;
        this.classList = await TeCommon.getTEClass(sid, this.tid);
        this.teacherList = [];
    }

    // 取得 該班級的老師列表
    public async getTeacher(clid: string) {
        this.searchForm.tid = '';
        this.teacherList = await TeCommon.getTeacher(2, clid, 0);
    }

    public async getResultList() {
        const item = {
            page: this.page,
            perpage: this.perpage,
            active: this.searchForm.active,
            grade: this.searchForm.grade,
            cid: this.searchForm.cid,
            sid: this.searchForm.sid,
            clid: this.searchForm.clid,
            tid: this.searchForm.tid,
            student: this.searchForm.student,
            date: this.searchForm.date,
            name: this.searchForm.name,
            teacher: this.tid,
            token: this.token,
        }
        const data = await HomeworkModel.teGetResultList(item) as response;
        TeCommon.checkToken(data.ERR_CODE as number);
        this.num = data.num as number;
        this.resultList = data.list as object;
        this.maxPage = Math.ceil(this.num/this.perpage);
    }

    private getPageList(page: number) {
        this.page = (page < 1) ? 1 : (page > this.maxPage) ?  this.maxPage : page;
        this.getResultList();
    }
}